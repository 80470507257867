import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
//import logo from "./logo.svg";
import "./App.css";
import Header from "./components/Header";
import Banner from "./components/Banner";
import Newsletter from "./components/Newsletter";
import NewTimetable from "./components/NewTimetable";
import Alert from "./components/Alert";
import InstallScreen from "./components/InstallScreen";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header />
                <Banner />
                <Newsletter />
                <Alert />
                <NewTimetable />
              </>
            }
          />
          <Route
            path="/install"
            element={
              <InstallScreen
                name="Katie's Yoga Collective App"
                logo={process.env.PUBLIC_URL + "/logo512.png"}
                url="https://timetable.katiesyogacollective.co.uk/"
                screenshots={[
                  process.env.PUBLIC_URL + "/KYC-Dashboard.jpg",
                  process.env.PUBLIC_URL + "/KYC-Class-Finder.jpg",
                  process.env.PUBLIC_URL + "/KYC-Signup.jpg",
                  process.env.PUBLIC_URL + "/KYC-Yoga.jpeg",
                ]}
                description="Install our app for a better experience."
              />
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
