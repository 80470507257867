import {
  isMobile,
  isFirefox,
  isIOS,
  browserVersion,
} from "mobile-device-detect";

import {
  Chrome,
  EllipsisVertical,
  Share,
  Smartphone,
  SquarePlus,
} from "lucide-react";

type platform = "native" | "firefox" | "ios" | "other";

function getPlatform(): platform {
  if (window.hasOwnProperty("BeforeInstallPromptEvent")) {
    return "native";
  } else if (isMobile && isFirefox) {
    return "firefox";
  } else if (isIOS && isMobile) {
    return "ios";
  } else {
    return "other";
  }
}

export default function platformHint() {
  switch (getPlatform()) {
    case "native":
      return <p></p>;
    case "firefox":
      return (
        <ol>
          <li>
            1. Tap{" "}
            <EllipsisVertical className="inline align-text-top" size={18} />{" "}
          </li>
          <li>
            2. Add app to Home Screen{" "}
            <Smartphone className="inline align-top" size={22} />
          </li>
        </ol>
      );
    case "ios":
      return (
        <ol>
          <li>
            1. Look for this icon on your phone, and tap share <Share className="inline align-top" size={22} />{" "}
          </li>
          <li>
            2. Scroll down and add to Home screen{" "}
            <SquarePlus className="inline align-top" size={22} />
          </li>
        </ol>
      );
    default:
      return (
        <p>
          Unfortunately this device is not supported, try using Chrome{" "}
          <Chrome className="inline align-top" size={22} />
        </p>
      );
  }
}
